import { alpha, Dialog, makeStyles, Slide, Theme } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import { TransitionProps } from "@material-ui/core/transitions";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/nextjs";
import clsx from "clsx";
import Head from "next/head";
import React, { FC, forwardRef, ReactElement, ReactNode, Ref, useCallback, useEffect, useMemo, useState } from "react";
import { AccountRecovery } from "../components/accounts/AccountRecovery";
import { CurrentUser } from "../components/CurrentUser";
import { GlobalAddPopover } from "../components/global-add/GlobalAddPopover";
import { Link } from "../components/Link";
import { Loading } from "../components/Loading";
import { OnboardingBuffet } from "../components/onboarding/OnboardingBuffet";
import { SyncStatus } from "../components/SyncStatus";
import { QuickAddTask } from "../components/tasks/QuickAddTask";
import { Tooltip } from "../components/Tooltip";
import { TrialExpirationBanner } from "../components/TrialExpirationBanner";
import { useAnalyticsContext } from "../context/AnalyticsContext";
import { AppContextActionType, AppContextNavState, useAppContext } from "../context/AppContext";
import { useCommandBarContext } from "../context/CommandBarContext";
import { useUserContext } from "../context/UserContext";
import { usePromise } from "../hooks/usePromise";
import IconDarkSvg from "../img/icon-dark.svg";
import { reclaim } from "../reclaim-api";
import { ConnectedAccount } from "../reclaim-api/Accounts";
import { Status } from "../subpages/status";
import { arrequal } from "../utils/arrays";
import { browser, platform } from "../utils/platform";
import { MainSidebar, MAIN_SIDEBAR_OPEN_WIDTH } from "./MainSidebar";

const APP_BAR_HEIGHT = 68;

const SlideTransition = forwardRef<unknown, TransitionProps>(
  (props: TransitionProps & { children?: ReactElement }, ref: Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
  )
);

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      flex: 1,
      position: "relative",
      display: "flex",
      flexDirection: "row",
      justifyContent: "stretch",
      height: "100%",
      minHeight: "100%",
      maxHeight: "100%",
    },
    sidebar: {},
    main: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
      overflowX: "hidden",
      [theme.breakpoints.up("md")]: {
        width: `calc(100vw - ${MAIN_SIDEBAR_OPEN_WIDTH}px)`,
      },
    },
    banners: {
      zIndex: 1,
    },
    appBar: {
      gridArea: "appbar",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "stretch",
      height: APP_BAR_HEIGHT,
      minHeight: APP_BAR_HEIGHT,
      backgroundColor: "transparent",
      borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.075)}`,
      zIndex: 2,
    },
    content: {
      zIndex: 3,
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "stretch",
      minHeight: 0,
    },
    toolbar: {
      display: "flex",
      flex: 1,
      height: "100%",
      paddingRight: theme.spacing(2),
      justifyContent: "space-between",
      [theme.breakpoints.up("md")]: {
        justifyContent: "flex-end",
        paddingRight: theme.spacing(0.75),
      },
    },
    logoButton: {
      marginRight: "auto",
      padding: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(1, 1.5),
      },
    },
    logoIcon: {
      maxHeight: 24,
      maxWidth: 24,
    },
    title: {
      flex: 1,
      padding: 0,
      fontSize: "1.5em",
      lineHeight: "1em",
      overflow: "visible",
      [theme.breakpoints.up("md")]: {
        marginLeft: theme.spacing(3),
        padding: 0,
        fontSize: "2em",
      },
      "&:empty": {
        display: "none",
      },
    },
    titleDivider: {
      color: theme.palette.grey.A100,
    },
    keyboardKey: {
      backgroundColor: theme.palette.grey[700],
      borderRadius: 4,
      marginLeft: theme.spacing(1.25),
      padding: theme.spacing(0, 0.5),
    },
    searchBtn: {
      padding: theme.spacing(1),
    },
  }),
  { index: 1, name: "AppLayout" }
);

type AppLayoutProps = {
  title: string | string[] | ReactElement;
  disableScroll?: boolean;
  disableTitle?: boolean;
  disableSync?: boolean;
  className?: string;
};

// eslint-disable-next-line max-lines-per-function
export const AppLayout: FC<AppLayoutProps> = ({
  title,
  disableScroll = false,
  disableTitle = false,
  disableSync,
  children,
  className,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const small = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    state: { nav, crumbs, title: docTitle },
    dispatch,
  } = useAppContext();
  const [{ user, status }] = useUserContext();
  const {
    state: { sentry },
  } = useAnalyticsContext();
  const {
    state: { CommandBar },
  } = useCommandBarContext();

  const {
    data: mainAccount,
    loading: mainAccountLoading,
    load: loadMainAccount,
  } = usePromise<ConnectedAccount>(reclaim.accounts.main, []);

  const [gettingStartedOpen, setGettingStartedOpen] = useState<boolean>(false);
  const [accountRecoveryOpen, setAccountRecoveryOpen] = useState<boolean>(false);

  const setNav = useCallback(
    (opts: Partial<AppContextNavState>) => {
      dispatch({
        type: AppContextActionType.Nav,
        payload: opts,
      });
    },
    [dispatch]
  );

  // Set document title
  useEffect(() => {
    if (typeof title === "string" && docTitle !== title) {
      dispatch({ type: AppContextActionType.Title, payload: title });
      dispatch({ type: AppContextActionType.Crumbs, payload: [title] });
      return;
    }

    if (Array.isArray(title) && !arrequal(title, crumbs)) {
      const page = title[title.length - 1];
      dispatch({ type: AppContextActionType.Crumbs, payload: title });
      dispatch({ type: AppContextActionType.Title, payload: page });
      return;
    }
  }, [crumbs, dispatch, docTitle, title]);

  // Shrink nav when switching to mobile layout
  useEffect(() => {
    if (small) setNav({ pin: false, hover: false, expand: false });
  }, [setNav, small]);

  // Force account recovery dialog if main account invalid
  useEffect(() => {
    if (!!mainAccountLoading || !mainAccount) return;
    if (!mainAccount.valid) setAccountRecoveryOpen(true);
  }, [mainAccount, mainAccountLoading]);

  const handleDrawerToggle = useCallback(
    (pin = !nav.pin) => {
      const next = pin || (browser().isBrowser && !!document.querySelector("#intercom-positioner-tree"));

      if (next !== nav.pin) setNav({ pin: next, hover: false, expand: false });
    },
    [nav.pin, setNav]
  );

  const handleCloseGettingStarted = useCallback(() => setGettingStartedOpen(false), []);

  const handleCloseAccountRecovery = useCallback(async () => {
    const res = await loadMainAccount();
    if (!!res.valid) setAccountRecoveryOpen(false);
  }, [loadMainAccount]);

  const renderTitle = useMemo(() => {
    if (disableTitle) return null;
    else
      return (
        <Typography variant="h1" color="inherit" noWrap className={classes.title}>
          {typeof title === "string" && title}
          {Array.isArray(title) &&
            title
              .map<ReactNode>((t, idx) => <span key={`${idx}-${t}`}>{t}</span>)
              .reduce((acc, curr, idx) => [
                acc,
                <span key={`${idx}-divider`} className={classes.titleDivider}>
                  {" "}
                  /{" "}
                </span>,
                curr,
              ])}
        </Typography>
      );
  }, [classes.title, classes.titleDivider, disableTitle, title]);

  return (
    <Box key="AppLayout" className={clsx(classes.root, className)}>
      <Head>
        <title>{/^Reclaim/.test(docTitle) ? docTitle : `${docTitle} | Reclaim`}</title>
        <meta name="robots" content="noindex" key="robots" />
      </Head>

      <MainSidebar className={classes.sidebar} overlayMode={small} />

      {/* Main content */}
      <Box
        className={classes.main}
        style={{
          overflow: disableScroll ? "hidden" : undefined,
        }}
      >
        <Box className={classes.banners}>
          <TrialExpirationBanner />
        </Box>

        <Box className={classes.appBar}>
          <Hidden smDown>{renderTitle}</Hidden>
          <Toolbar disableGutters className={classes.toolbar}>
            <Hidden mdUp>
              <IconButton
                component={Link}
                color="inherit"
                aria-label="Reclaim.ai"
                href="/"
                className={classes.logoButton}
              >
                <IconDarkSvg className={classes.logoIcon} />
              </IconButton>
              {renderTitle}
            </Hidden>

            {!disableSync && <SyncStatus />}

            {!!user && (
              <Box data-intercom-target="nav-quick-add">
                <GlobalAddPopover>
                  <QuickAddTask />
                </GlobalAddPopover>
              </Box>
            )}

            <Tooltip
              title={
                <>
                  Quick search
                  <span className={classes.keyboardKey}>{platform().isMac ? "⌘" : "Ctrl"} K</span>
                </>
              }
              placement="bottom"
            >
              <IconButton
                className={classes.searchBtn}
                aria-label="search"
                color="inherit"
                onClick={(e) => {
                  e.preventDefault();
                  CommandBar?.open();
                }}
              >
                <SearchRoundedIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <Hidden smDown>{!!user && <CurrentUser withUserName={false} size="large" />}</Hidden>

            <Hidden mdUp>
              <IconButton edge="end" color="inherit" aria-label="toggle menu" onClick={() => handleDrawerToggle(true)}>
                <MenuRoundedIcon />
              </IconButton>
            </Hidden>
          </Toolbar>
        </Box>
        <Box component="main" className={classes.content}>
          <SentryErrorBoundary
            fallback={
              <Status
                code={5000}
                message="We are so sorry, but there seems to be an issue. Our engineers have been notified and are working on a fix."
              />
            }
            showDialog
            onError={() => {
              sentry?.addBreadcrumb({
                category: "error",
                message: "Page Error",
                level: sentry.Severity.Critical,
              });
            }}
          >
            {status === "loading" ? <Loading /> : children}
          </SentryErrorBoundary>

          {/* <AppUpdate /> */}
        </Box>

        {/* Get Started dialog */}
        <Dialog
          fullScreen
          open={gettingStartedOpen}
          onClose={handleCloseGettingStarted}
          TransitionComponent={SlideTransition}
          TransitionProps={{ timeout: 250 }}
        >
          <OnboardingBuffet onClose={handleCloseGettingStarted} />
        </Dialog>

        {/* Account recovery dialog */}
        <Dialog
          fullScreen
          open={accountRecoveryOpen}
          onClose={handleCloseAccountRecovery}
          TransitionComponent={SlideTransition}
          TransitionProps={{ timeout: 250 }}
        >
          <AccountRecovery onClose={handleCloseAccountRecovery} />
        </Dialog>
      </Box>
    </Box>
  );
};
