import { Box, Divider, Drawer, Hidden, Icon, IconButton, List, makeStyles, Typography } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import BarChartRoundedIcon from "@material-ui/icons/BarChartRounded";
import CachedIcon from "@material-ui/icons/Cached";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import FolderOutlinedIcon from "@material-ui/icons/FolderOutlined";
import HelpOutlineRounded from "@material-ui/icons/HelpOutlineRounded";
import MenuOpenRoundedIcon from "@material-ui/icons/MenuOpenRounded";
import RepeatRoundedIcon from "@material-ui/icons/RepeatRounded";
import clsx from "clsx";
import { MouseEventHandler, useCallback, useEffect, useRef, VFC } from "react";
import { Link } from "../components/Link";
import { UserNav } from "../components/UserNav";
import { useAnalyticsContext } from "../context/AnalyticsContext";
import { AppContextActionType, AppContextNavState, useAppContext } from "../context/AppContext";
import { useUserContext } from "../context/UserContext";
import { useOpenIntercom } from "../hooks/useOpenIntercom";
import { useOurRouter } from "../hooks/useOurRouter";
import CogIcon from "../img/cog-icon.svg";
import IconSvg from "../img/icon.svg";
import ShareIcon from "../img/share-icon.svg";
import UsersIcon from "../img/users-icon.svg";
import { browser } from "../utils/platform";
import { MainSidebarCollapsableItem } from "./MainSidebarCollapsableItem";
import { MainSidebarItem } from "./MainSidebarItem";

export const MAIN_SIDEBAR_CLOSED_WIDTH = 64;
export const MAIN_SIDEBAR_OPEN_WIDTH = 230;
const HIDE_SHARE_ANIMATION_DELAY_MS = 5 * 60 * 1000; // 5 minutes

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "stretch",
      position: "relative",
    },
    drawerPaper: {
      position: "relative",
      width: MAIN_SIDEBAR_OPEN_WIDTH,
      backgroundColor: theme.colors.logo.darkness,
      transition: theme.transitions.create(["width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
    },
    drawerPaperClosed: {
      width: MAIN_SIDEBAR_CLOSED_WIDTH,
      transition: theme.transitions.create(["width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      flex: 0,
      color: theme.palette.common.white,
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(1),
      },
    },
    branding: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    logoText: {
      flexGrow: 1,
      marginLeft: -2,
      fontWeight: theme.typography.fontWeightMedium,
      [theme.breakpoints.up("md")]: {
        marginLeft: 6,
      },
    },
    pinIcon: {
      padding: 10,
    },
    navSpacer: {
      flex: 1,
    },
    nestedList: {
      paddingTop: 0,
    },
    paddedIcon: {
      padding: 1,
    },
    logoButton: {
      marginRight: "auto",
      padding: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(1, 1.5),
      },
    },
    logoIcon: {
      maxHeight: 24,
      maxWidth: 24,
    },
    // eslint-disable-next-line mui-unused-classes/unused-classes
    "@keyframes navLink__bg__fadeout": {
      from: { opacity: 1 },
      to: { opacity: 0 },
    },
    share: {
      position: "relative",

      "&:after": {
        content: "''",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,

        animationName: "$navLink__bg__fadeout",
        animationDuration: 500,
        animationDelay: `${HIDE_SHARE_ANIMATION_DELAY_MS}ms`,
        animationIterationCount: 1,
        animationFillMode: "forwards",
        background: "url(/img/confetti.gif) -1px 0",
        backgroundSize: "cover",
      },
    },
  }),
  {
    classNamePrefix: "MainSidebar",
  }
);

export type MainSidebarJSSClassKey = keyof ReturnType<typeof useStyles>;

export type MainSidebarProps = {
  classes?: Partial<ClassNameMap<MainSidebarJSSClassKey>>;
  className?: string;
  overlayMode?: boolean;
};

export const MainSidebar: VFC<MainSidebarProps> = ({ className, classes: extClasses, overlayMode }) => {
  const classes = useStyles({
    classes: extClasses,
  });
  const {
    state: { segment },
  } = useAnalyticsContext();
  const [{ user }] = useUserContext();
  const {
    state: { nav },
    dispatch,
  } = useAppContext();

  const router = useOurRouter();

  const setNav = useCallback(
    (opts: Partial<AppContextNavState>) => {
      dispatch({
        type: AppContextActionType.Nav,
        payload: opts,
      });
    },
    [dispatch]
  );

  const handleDrawerToggle = useCallback(
    (pin = !nav.pin) => {
      const next = pin || (browser().isBrowser && !!document.querySelector("#intercom-positioner-tree"));

      if (next !== nav.pin) setNav({ pin: next, hover: false, expand: false });
    },
    [nav.pin, setNav]
  );

  const hoverTimeout = useRef<NodeJS.Timeout>();

  const resetHoverTimer = useCallback(() => hoverTimeout.current && clearTimeout(hoverTimeout.current), []);

  useEffect(() => {
    resetHoverTimer();
  }, [nav.expand, resetHoverTimer]);

  const handleLogoClick = useCallback(
    (e) => {
      if (!browser().isMobile) return;
      e.preventDefault();
      setNav({ hover: !nav.hover });
    },
    [nav.hover, setNav]
  );

  const { openIntercomStatic } = useOpenIntercom();

  const handleHover = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      if (overlayMode) return;

      resetHoverTimer();

      if (browser().isBrowser && !!document.querySelector("#intercom-positioner-tree") && !nav.pin) {
        setNav({ pin: true, hover: true });
        return;
      }

      if ("mouseenter" === e.type) {
        hoverTimeout.current = setTimeout(() => {
          if (!nav.hover) setNav({ hover: true });
        }, 150);
      } else {
        hoverTimeout.current = setTimeout(() => {
          if (!!nav.hover) setNav({ hover: false });
        }, 300);
      }
    },
    [overlayMode, resetHoverTimer, nav.pin, nav.hover, setNav]
  );

  const handleBackdropClick = useCallback(() => {
    if (nav.pin || nav.hover || nav.expand) setNav({ pin: false, hover: false, expand: false });
  }, [nav.expand, nav.hover, nav.pin, setNav]);

  return (
    <Drawer
      variant={overlayMode ? "temporary" : "permanent"}
      anchor="left"
      className={clsx(classes.root, className)}
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.drawerPaperClosed]: !nav.open,
        }),
      }}
      open={nav.open}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
      {...(overlayMode ? { onBackdropClick: handleBackdropClick } : {})}
    >
      <Box className={classes.toolbarIcon}>
        <Box className={classes.branding}>
          <IconButton
            component={Link}
            color="inherit"
            aria-label="Reclaim.ai"
            href="/"
            className={classes.logoButton}
            onClick={handleLogoClick}
          >
            <IconSvg className={classes.logoIcon} />
          </IconButton>
          {nav.open && (
            <>
              <Typography variant="h4" className={classes.logoText}>
                Reclaim
              </Typography>
              {!overlayMode && (
                <IconButton
                  className={classes.pinIcon}
                  color="inherit"
                  aria-label={`${nav.pin ? "close" : "open"} menu`}
                  onClick={() => handleDrawerToggle()}
                >
                  {nav.pin ? (
                    <MenuOpenRoundedIcon fontSize="small" />
                  ) : (
                    <Icon
                      className="fa fa-thumbtack"
                      style={{
                        fontSize: 16,
                        opacity: 0.8,
                      }}
                    />
                  )}
                </IconButton>
              )}
            </>
          )}
        </Box>
      </Box>

      <Divider />
      <List component="nav" disablePadding>
        <MainSidebarItem href="/planner" icon={<CalendarTodayOutlinedIcon fontSize="small" />}>
          Planner
        </MainSidebarItem>
        <MainSidebarItem href="/stats" icon={<BarChartRoundedIcon fontSize="small" />}>
          Stats
        </MainSidebarItem>
      </List>
      <Divider />

      <List component="nav" disablePadding>
        <MainSidebarItem href="/tasks" icon={<AssignmentTurnedInOutlinedIcon fontSize="small" />}>
          Tasks
        </MainSidebarItem>
        <MainSidebarItem href="/habits" icon={<RepeatRoundedIcon fontSize="small" />}>
          Habits
        </MainSidebarItem>

        {!!user?.features?.smartOneOnOnes?.enabled && (
          <MainSidebarItem href="/one-on-ones" icon={<UsersIcon className={classes.paddedIcon} />}>
            Smart 1:1s
          </MainSidebarItem>
        )}

        {!!user?.features?.projects?.enabled && (
          <MainSidebarItem href="/projects" icon={<FolderOutlinedIcon fontSize="small" />}>
            Projects
          </MainSidebarItem>
        )}

        {user?.features.sync?.enabled && (
          <MainSidebarCollapsableItem
            href="/sync"
            icon={<CachedIcon fontSize="small" />}
            label="Calendar Sync"
            storageKey="sync"
            mountOpen={router.pathname.startsWith("/sync")}
          >
            <List component="div" className={classes.nestedList}>
              <MainSidebarItem href="/sync">All Syncs</MainSidebarItem>
              <MainSidebarItem href="/sync/activity">Activity</MainSidebarItem>
            </List>
          </MainSidebarCollapsableItem>
        )}
      </List>
      <Box className={classes.navSpacer} />

      <List component="nav" disablePadding>
        <MainSidebarCollapsableItem
          href="/settings/general"
          icon={<CogIcon fontSize="small" />}
          label="Settings"
          storageKey="settings"
          mountOpen={
            router.pathname.startsWith("/settings") ||
            router.pathname.startsWith("/users/self") ||
            (browser().isBrowser && !!document.querySelector("#intercom-positioner-tree"))
          }
        >
          <List component="div" className={classes.nestedList}>
            <MainSidebarItem href="/settings/general">General</MainSidebarItem>
            <MainSidebarItem href="/users/self">Profile</MainSidebarItem>
            <MainSidebarItem href="/settings/hours" data-intercom-target="nav-settings-hours">
              Hours
            </MainSidebarItem>
            <MainSidebarItem href="/settings/colors">Colors</MainSidebarItem>
            <MainSidebarItem href="/settings/notifications">Notifications</MainSidebarItem>
            <MainSidebarItem href="/settings/integrations">Integrations</MainSidebarItem>
          </List>
        </MainSidebarCollapsableItem>

        <MainSidebarItem icon={<HelpOutlineRounded className={classes.paddedIcon} />} onClick={openIntercomStatic}>
          Help
        </MainSidebarItem>
        <MainSidebarItem
          href="/share-reclaim"
          icon={<ShareIcon className={classes.paddedIcon} />}
          className={classes.share}
          data-intercom-target="nav-share-reclaim"
          onClick={(e) => {
            segment?.track("Share Reclaim Clicked", {
              category: "Nav",
            });
          }}
        >
          Share Reclaim
        </MainSidebarItem>

        <Hidden mdUp>{!!user && <UserNav isCollapsible />}</Hidden>
      </List>
    </Drawer>
  );
};
