import { Box, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import React, { useCallback, useMemo } from "react";
import { useAnalyticsContext } from "../../context/AnalyticsContext";
import { useOurRouter } from "../../hooks/useOurRouter";
import { SUPPORT_EMAIL } from "../../utils/consts";
import { Link } from "../Link";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch",
    marginBottom: theme.spacing(2),
  },
  alert: {
    margin: theme.spacing(0.5, 0),
  },
}));

export type AccountErrorsProps = {
  errors?: (string | Error) | (string | Error)[];
};

export const AccountErrors: React.FC<AccountErrorsProps> = ({ errors }) => {
  const classes = useStyles();

  const router = useOurRouter();

  const {
    state: { intercom },
  } = useAnalyticsContext();

  const handleSupportClick = useCallback(
    (e) => {
      if (!intercom) return;
      e.preventDefault();

      intercom("show");
    },
    [intercom]
  );

  const list = useMemo(() => {
    const { op, mode, cid } = router.query || {};
    const authError = router.query.error || router.query.state?.error;
    const list: (string | Error)[] = [];

    if (errors) list.push(...(Array.isArray(errors) ? errors : [errors]));

    if (!!authError && "google" === op) {
      list.push(...(Array.isArray(authError) ? authError : [authError]));
    } else if (op === "google" && !cid) {
      list.push("invalid_state");
      console.error("Response is missing cid", op, mode);
    }

    return list;
  }, [errors, router.query]);

  const parsed = useMemo(
    () =>
      list.map((error) => {
        // parse errors
        if (error instanceof Error) {
          // TODO (IW): Handle various non-200 response codes
          const type = error["type"];
          const status = error["status"];
          const message = error["body"] || error["statusText"] || error["message"];
          return `${message || "Unexpected error"} (${status || type})`;
        }

        // parse strings
        if (typeof error === "string") {
          switch (error) {
            case "access_denied":
              return "User cancelled authentication (access denied).";
            case "auth_failed":
              return "Authentication failed.";
            case "credential_not_found":
              return "Credential not found.";
            case "not_a_google_calendar_user":
              return "Not a Google Calendar account. We're working to add more providers, but only support Google Calendar at this time.";
            case "wrong_account":
              return "Wrong account connected. Please make sure to select the correct email address when prompted.";
            case "invalid_scopes":
              return (
                <span>
                  Insufficient access. You need to allow all requested scopes to proceed.{" "}
                  <Link
                    target="reclaim-help"
                    href="//help.reclaim.ai/en/articles/3600703-what-does-lifework-do-with-my-data"
                  >
                    Learn more about how we use and protect your data.
                  </Link>
                </span>
              );
            case "primary_exists":
            case "access_token":
            case "invalid_state":
            case "invalid_id_token":
            case "unknown":
            default:
              return `Unexpected error (${error || "unknown"}).`;
          }
        }
      }),
    [list]
  );

  if (!list.length) return null;

  return (
    <Box className={classes.root}>
      {!!list.length && (
        <Alert className={classes.alert} severity="info">
          Please contact{" "}
          <Link
            href={`mailto:${SUPPORT_EMAIL}?subject=${encodeURIComponent(
              "Error connecting calendar"
            )}&body=${encodeURIComponent("Errors:\n\n" + parsed.join("\n") + "\n\n")}`}
            onClick={handleSupportClick}
          >
            support
          </Link>{" "}
          for help connecting accounts.
        </Alert>
      )}
      {parsed.map((error, idx) => (
        <Alert key={idx} className={classes.alert} severity="error">
          {error}
        </Alert>
      ))}
    </Box>
  );
};
