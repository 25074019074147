import {
  alpha,
  Box,
  ListItem,
  ListItemIcon,
  ListItemProps,
  ListItemText,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import { createContext, FC, MouseEventHandler, ReactNode, useContext } from "react";
import { Link } from "../components/Link";

const BASE_H_PADDING = 22;

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(1.5, `${BASE_H_PADDING}px`),
      color: theme.palette.common.white,
      cursor: "pointer",
      display: "flex",
      gap: theme.spacing(2.5),
      fontSize: 15,

      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.075),
      },
    },
    icon: {
      flex: "0 0 auto",
      minWidth: 0,
      justifyContent: "center",
      color: theme.palette.common.white,
      opacity: 0.8,
      width: 20,
      height: 20,
    },
    label: {
      flex: "1 1 auto",
      whiteSpace: "nowrap",
      margin: 0,
    },
    labelTypography: {
      fontSize: "inherit",
    },
    link: {
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none",
      },
      "&:not($parentLink).active": {
        backgroundColor: alpha(theme.palette.common.white, 0.05),
      },
    },
    action: {
      color: "white",
      height: 20,
      display: "flex",
      alignItems: "center",
    },
    hasAction: {
      paddingRight: theme.spacing(),
    },
    isNested: {
      padding: theme.spacing(1, 1, 1, 5.25),
      fontSize: 13,
    },
  }),
  {
    classNamePrefix: "MainSidebarItem",
  }
);

export const MenuSidebarItemIndentContext = createContext(0);

export type MainSidebarItemJSSClassKey = keyof ReturnType<typeof useStyles>;

export type MainSidebarItemProps = {
  classes?: Partial<ClassNameMap<MainSidebarItemJSSClassKey>>;
  className?: string;
  ListItemClasses?: ListItemProps["classes"];
  icon?: ReactNode;
  href?: string;
  action?: ReactNode;
  onClick?: MouseEventHandler<HTMLElement>;
};

export const MainSidebarItem: FC<MainSidebarItemProps> = ({
  className,
  classes: extClasses,
  icon,
  children,
  href,
  ListItemClasses,
  onClick,
  action,
}) => {
  const classes = useStyles({
    classes: extClasses,
  });

  const isLink = typeof href === "string";
  const indentLevel = useContext(MenuSidebarItemIndentContext);
  const theme = useTheme();

  return (
    <ListItem
      button
      component={isLink ? Link : "div"}
      href={href}
      onClick={onClick}
      className={clsx(classes.root, className, {
        [classes.link]: isLink,
        [classes.hasAction]: !!action,
        [classes.isNested]: indentLevel > 0,
      })}
      classes={ListItemClasses}
      style={{ paddingLeft: BASE_H_PADDING + theme.spacing(indentLevel * 5) }}
    >
      {icon && <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>}
      <ListItemText primary={children} className={classes.label} classes={{ primary: classes.labelTypography }} />
      {action && <Box className={classes.action}>{action}</Box>}
    </ListItem>
  );
};
